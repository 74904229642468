import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 批发商ID
    wholesalerId: JSON.parse(localStorage.getItem('wholesaler_id')) || '',
    // 用户信息
    userInfo: JSON.parse(localStorage.getItem('wh_user_info')) || {},
    // 订单信息
    orderInfo: {}
  },
  mutations: {
    SET_WHOLESALER_ID (state, data) {
      state.wholesalerId = data
      localStorage.setItem('wholesaler_id', JSON.stringify(data) || '')
    },
    SET_USER_INFO (state, data) {
      state.userInfo = data
      localStorage.setItem('wh_user_info', JSON.stringify(data) || '')
      if (router.app._route.name === 'Login') {
        router.push({
          name: 'Index',
          query: {
            id: state.wholesalerId
          }
        })
      }
    },
    DELETE_USER_INFO (state) {
      state.userInfo = ''
      localStorage.removeItem('token')
      localStorage.removeItem('wh_user_info')
      router.push({
        name: 'Login'
      })
    },
    SET_ORDER_INFO (state, data) {
      state.orderInfo = data
      localStorage.setItem('order_info', JSON.stringify(data))
    }
  },
  actions: {
    // 保存批发商ID
    setWholesalerId (context, data) {
      context.commit('SET_WHOLESALER_ID', data)
    },
    // 保存用户信息
    setUserInfo (context, data) {
      context.commit('SET_USER_INFO', data)
    },
    deleteUserInfo (context) {
      context.commit('DELETE_USER_INFO')
    },
    // 保存订单信息
    setOrderInfo (context, data) {
      context.commit('SET_ORDER_INFO', data)
    }
  },
  modules: {
  }
})
