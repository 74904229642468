<template>
  <div class="web-order-warpper">

    <div style='height: 20px;'></div>
    <div class="st-spacing-main">
      <div class="state-item">
        <div class="state-body">
          <div class="icon">
            <i></i>
          </div>
          <p>
            已付款
          </p>
        </div>
        <svg
          class="waves"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shape-rendering="auto">
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g class="parallax">
            <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(95,226,176,0.3)" />
            <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(95,226,176,0.2)" />
            <use xlink:href="#gentle-wave" x="48" y="7" fill="rgba(95,226,176,1)" />
          </g>
        </svg>
      </div>
      <div class="pay-item">
        <div class="st-list-body">
          <li>
            主体信息
            <i></i>
          </li>
          <li v-if="seller.name">
            主体名称：
            <span>{{ seller.name }}</span>
          </li>
          <li v-if="seller.market_name">
            主体地址：
            <span style='max-width: 80%;'>{{ seller.market_name }}{{ seller.customCode }}</span>
          </li>
          <li v-if="seller.name">
            店铺名称：
            <span>{{ seller.name }}</span>
          </li>
          <li v-if="seller.desc">
            经营范围：
            <span>{{ seller.desc }}</span>
          </li>
          <li v-if="seller.tax">
            统一社会信用代码：
            <span>{{ seller.tax }}</span>
          </li>

          <li v-if="seller.customCode">
            摊位号：
            <span>{{ seller.customCode }}</span>
          </li>
          <li v-if="seller.tel">
            手机号
            <span>{{ seller.tel | phoneNum}}</span>
          </li>
          <li v-if="seller.reg_id">
            营业执照：
            <span>{{ seller.reg_id }}</span>
          </li>
        </div>
        
      </div>
    </div>

    <div class="footer-button">
      <b class="button--large" @click="onSideBtn">
        <span>查看溯源信息</span>
        <i></i>
      </b>
    </div>
    <div class="btn-seat"></div>


  </div>
</template>

<script>

export default {
  name: 'WebOrder',

  data () {
    return {
      seller: {}
    }
  },
  mounted () {
    this.initialize()
    this.back()
  },
  filters: {
    phoneNum (value) {
      if (value) {
        return value.slice(0,3) + '****' + value.slice(-4)
      }
    }
  },
  methods: {
    initialize () {
      let merchld = this.$route.query.merchId
      this.$api.orderTracking.profiles({
        merch_id: merchld
      }).then(res => {
        if (res.data.success) {
          this.seller = res.data.result
        }
      })
    },
    back () {
      window.history.pushState(null, null, document.URL)
      window.history.forward(1)
      window.addEventListener('popstate', function () {
        history.pushState(null, null, document.URL)
      })
    },

    onSideBtn () {
      this.$router.push({ name: 'Tracking', query: { id: this.seller._id } })
    }
  }
}
</script>

<style lang="less" scoped>
.web-order-warpper {

  .st-spacing-main {
    margin: 0  .2rem;
    background: #fff;
    border-radius: .4rem;
    box-shadow: 0 0 .2667rem 0 rgba(187, 187, 187, .1);

    overflow: hidden;
    .state-item {
      height: 4.2rem;
      position: relative;
      &:after,
      &:before {
        content: '';
        width: .58rem;
        height: .58rem;
        background-color: #F9F9F9;
        border-radius: 50%;
        box-shadow: inset 0 0 .6667rem 0 rgba(187, 187, 187, .1);
        position: absolute;
        bottom: -.29rem;
        z-index: 1;
      }
      &:after {
        left: -.29rem;
      }
      &:before {
        right: -.29rem;
      }
      .state-body {
        height: 2.92rem;
        background: #5FE2B0 url("https://smartracing.oss-cn-hangzhou.aliyuncs.com/assets/images/smartracing-pay/line-states.png") center top no-repeat;
        background-size: 100% auto;
        overflow: hidden;
        .icon {
          width: 1rem;
          height: 1rem;
          background: #fff url("https://smartracing.oss-cn-hangzhou.aliyuncs.com/assets/images/smartracing-pay/icon/hook-small.png") center center no-repeat;
          background-size: auto .5rem;
          box-shadow: 0 .08rem .1333rem 0 rgba(76,193,147,0.5);
          border-radius: 50%;
          margin: .8rem auto .2rem;
          position: relative;
          overflow: hidden;
          animation: rotate .6s;
          i {
            width: 100%;
            height: 100%;
            background: #fff;
            position: absolute;
            left: 1.4rem;
            top: 0;
            animation: out 1s ease-in;
          }
        }
        p {
          font-size: .36rem;
          color: #fff;
          text-align: center;
          line-height: .6rem;
          animation: bounce 1s;
          s {
            font-size: .5067rem;
          }
        }
      }
    }
    .pay-item {
      position: relative;
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        background-image: linear-gradient(to right, #84E9BA 0%, #84E9BA 50%, transparent 50%);
        background-size: 8px 1px;
        background-repeat: repeat-x;
        position: absolute;
        left: 0;
        top: 0;
      }
      .st-item-header {
        line-height: .6rem;
        padding: .52rem .4rem 0;
        &:after {
          display: none;
        }
      }
      .st-list-body {
        padding: .54rem .4rem;
        position: relative;
        li {
          font-size: .26rem;
          line-height: .5rem;
          color: #666;
          padding: .27rem 0 !important;
          position: relative;
          overflow: hidden;
          i {
            width: .6rem;
            height: .6rem;
            background: url("https://smartracing.oss-cn-hangzhou.aliyuncs.com/assets/images/smartracing-pay/icon/icon-small.png") center center no-repeat;
            background-size: auto 100%;
            margin-top: -.45rem;
            position: absolute;
            right: 0;
            top: 50%;
          }
          span {
            max-width: 61%;
            font-size: .24rem;
            color: #999;
            text-align: right;
            float: right;
          }
        }
      }
      .st-list-body {
        font-size: .32rem;
        line-height: .44rem;
        li {
          min-height: .84rem;
          color: #666;
          padding: .2rem 0;
          box-sizing: border-box;
          h3, h4, h5 {
            line-height: .44rem;
            float: left;
          }
          p, .list-content {
            padding-left: 2.2rem;
            position: relative;
          }
          h3, b {
            color: #333;
          }
          h5, s {
            color: #999;
          }
          input {
            width: 100%;
            height: .44rem;
            font-size: .32rem;
          }
          em, .st-copy-btn {
            margin-top: -.22rem;
            position: absolute;
            right: 0;
            top: 50%;
          }
        }
      }

      .list-body--small,
      .list__item {
        li {
          padding: .38rem .4rem;
        }
      }
      .list__ends,
      .list__item {
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          h3, h4, h5 {
            float: none;
          }
          p {
            padding-left: 0;
          }
        }
        .silent-list {
          color: #999;
        }
      }
    }
  }
  .side-btn {
    background: rgba(105,218,211,1);
    box-shadow: 0 0 .2667rem 0 rgba(187,187,187,0.4);
    border-radius: .8rem 0 0 .8rem;
    padding: .13rem;
    display: flex;
    align-items: center;
    position: fixed;
    right: 0;
    bottom: 2.8rem;
    z-index: 9;
    img {
      width: 1.067rem;
    }
    .btn-text {
      font-size: .3467rem;
      font-weight: bold;
      line-height: .5rem;
      color: #fff;
      padding: 0 .16rem 0 .24rem;
    }
  }
  .header-total {
    font-size: .4rem;
    s {
      color: #F74E62;
    }
  }

  .footer-button {
    width: 100%;
    padding: .4rem;
    padding-bottom: calc(.4rem + env(safe-area-inset-bottom));
    box-sizing: border-box;
    background: #fff;
    box-shadow: 0 0 .2667rem 0 rgba(187, 187, 187, .1);
    border-radius: .64rem .64rem 0 0;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 999;
    b {
      width: 100%;
      line-height: 1.07rem;
      text-align: center;
      font-size: .4rem;
      color: #fff;
      background: linear-gradient(132deg, #84e9ba 0%, #00cf96 100%);
      border-radius: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      &.button--large {
        font-size: .36rem;
        i {
          width: .2rem;
          height: .3rem;
          background: url("https://smartracing.oss-cn-hangzhou.aliyuncs.com/assets/images/smartracing-pay/icon/right-arrow-w.png") center center no-repeat;
          background-size: 100% auto;
          margin-left: .134rem;
          display: inline-block;
        }
      }
    }
  }

  .btn-seat {
    height: 2rem;
  }
}



/* 波浪动画 */
.waves {
  position:relative;
  width: 100%;
  height: 15vh;
  transform: rotateX(-180deg);
  perspective-origin: center center;
  margin-bottom:-7px;
  min-height:100px;
  max-height:150px;
}

.parallax > use {
  animation: move-forever 5s cubic-bezier(.55,.5,.45,.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -1s;
  animation-duration: 3s;
}
.parallax > use:nth-child(2) {
  animation-delay: -2s;
  animation-duration: 5s;
}
.parallax > use:nth-child(3) {
  animation-delay: -1s;
  animation-duration: 7s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px,0,0);
  }
  100% {
    transform: translate3d(85px,0,0);
  }
}


/* 波浪动画 */
@-webkit-keyframes out {
  0%,
  40% {
    left: 0;
  }
  100% {
    left: 1.4rem;
  }
}

@-webkit-keyframes rotate {
  0%   {
    transform: rotateY(180deg);
  }
  50% {
    transform: translateY(-1.3rem) rotateY(0);
  }
  100% {
    transform: rotateY(0);
  }
}

@-webkit-keyframes bounce {
  0%,
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}



@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }
  .content {
    height: 30vh;
  }
  h1 {
    font-size: 24px;
  }
}
</style>