<template>
  <div class="st-wrapper register-wrapper">
    <div class="st-item">
      <div class="st-item-header">经营户信息</div>
      <ul class="register-items">
        <li>店铺名称：{{ userInfo.name }}</li>
        <li>档口号：{{ userInfo.market_name }}{{ userInfo.customCode }}</li>
        <li>主营：{{ userInfo.desc }}</li>
      </ul>
    </div>
    <div class="st-item">
      <div class="st-item-header">进场信息</div>
      <ul class="register-items">
        <li>产品种类：{{ registerInfo.kind }}</li>
        <li>产品品名：{{ registerInfo.goods_name }}</li>
        <li>运送车牌号：{{ registerInfo.license_plate_number }}</li>
        <li>产品产地：{{ registerInfo.place_of_origin }}</li>
        <li>进场数量：{{ registerInfo.number_of_branches }}</li>
        <li>进场时间：{{ registerInfo.time }}</li>
        <li>产地检疫证号：{{ registerInfo.inspection_and_quarantine }}</li>
      </ul>
    </div>
    <div class="st-item">
      <div class="st-item-header">安心检测</div>
      <ul class="image-items">
        <li
          v-for="item in images"
          :key="item"
          @click="imageShow">
          <img :src="item" alt="">
        </li>
      </ul>
    </div>

    <footer-button
      text="返回首页"
      @confirm="goHome"
    />

    <van-image-preview
      v-model="imagePreviewShow"
      :images="images">
    </van-image-preview>

  </div>
</template>

<script>
import FooterButton from '../../../components/footer/button/Index'
import { ImagePreview, Toast } from 'vant'
import moment from 'moment'

export default {
  name: '',
  components: {
    FooterButton,
    [ImagePreview.Component.name]: ImagePreview.Component
  },
  data () {
    return {
      userInfo: {},
      registerInfo: {},

      imagePreviewShow: false,
      images: []
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.getUserInfo()
      this.getRegisterInfo()
    },
    // 获取批发商基本信息
    getUserInfo () {
      this.$api.users.profiles({
        id: this.$route.query.merchId
      }).then(res => {
        if (res.data.success) {
          this.userInfo = res.data.result
        } else {
          Toast('获取不到经营户信息')
        }
      })
    },
    // 获取进场登记信息
    getRegisterInfo () {
      this.$api.tracing.register.details({
        id: this.$route.query.productId
      }).then(res => {
        if (res.status === 200) {
          this.registerInfo = res.data
          this.images = this.registerInfo.inspection_and_quarantine_list
          this.registerInfo.create_time
            ? this.registerInfo.time = moment.unix(this.registerInfo.create_time).format('YYYY-MM-DD HH:mm:ss')
            : ''
        } else {
          Toast('获取不到进场登记信息')
        }
      })
    },
    // 返回首页
    goHome () {
      this.$router.push({
        name: 'Index',
        query: {
          id: this.$route.query.merchId
        }
      })
    },
    // 预览图片
    imageShow () {
      this.imagePreviewShow = true
    }
  }
}
</script>

<style lang="less" scoped>
  @import "./index";
</style>
