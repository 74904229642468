<template>
  <div class="empty-wrapper st-body" :style="{ height: h }">
    <div class="empty-item" :class="icon + '-item'">
      <i></i>
      <p>{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    icon: {
      type: String
    },
    text: {
      type: String
    },
    h: {
      type: String,
      default: () => '100vh'
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang="less" scoped>
  @import "./index";
</style>
