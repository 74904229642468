<template>
  <div class="wait-paid st-wrapper">
    <div class="st-item money-item">
      <div class="item-header">请输入支付金额</div>
      <div class="item-body">
        <s>¥</s>
        <input
          v-model="totalPrice"
          :placeholder="total"
          type='number'
          class="price-input"
          @input="rechargeChange()"
        >
      </div>
    </div>

    <div class="st-item order-item">
      <div class="st-item-header">订单信息</div>
      <ul class="item-body">
        <li
          v-for="item in productItems"
          :key="item.id"
        >
          <div class="food-image">
            <img :src="item.goodsImage || productIcon" alt="">
          </div>
          <div class="item-main">
            <div class="item-info">
              <p class="name">{{ item.goodsName }}</p>
              <p class="price">
                ¥
                <s>{{ item.total }}</s>
              </p>
            </div>
            <div class="item-attr">
              <p>
                {{ item.goodsPrice }}元/{{ item.unit }}
                <i>×</i>
                {{ item.buyNumber }}
              </p>
            </div>
          </div>
        </li>
      </ul>
      <div class="item-footer">
        <span>订单金额(元)：</span>
        <b>{{ total }}</b>
      </div>
    </div>

    <total-price
      :total="totalPrice"
      :disabled="disabled"
      @submit="submit"
    />
  </div>
</template>

<script>
import TotalPrice from '../../../components/footer/total-price/Index'
import _ from 'lodash'
import {Toast} from "vant";

export default {
  name: '',
  components: {
    TotalPrice
  },
  data () {
    return {
      userInfo: {},
      orderInfo: {},
      productItems: [],
      productIcon: require('../../../assets/images/product-icon.png'),
      totalPrice: '',
      disabled: true
    }
  },
  computed: {
    total () {
      return this.moneyFormat(this.orderInfo.orderMoney)
    }
  },
  mounted () {
    this.getOrderDetails()
    this.getUserInfo()
  },
  methods: {
    // 获取订单详情
    getOrderDetails () {
      this.$api.orders.details({
        orderId: this.$route.query.orderId
      }).then(res => {
        if (res.data.success) {
          this.disabled = false
          this.orderInfo = res.data.map
          let items = res.data.map.data
          if (items.length > 0) {
            this.productItems = _.map(items, item => {
              item.total = this.moneyFormat(Number(item.goodsPrice) * Number(item.buyNumber))
              return item
            })
          }
          if (Number(this.totalPrice) <= 0) {
            this.disabled = true
          }
        } else {
          Toast('查询不到该订单信息')
        }
      })
    },
    // 获取批发商基本信息
    getUserInfo () {
      this.$api.users.profiles({
        id: this.$route.query.merchId
      }).then(res => {
        if (res.data.success) {
          this.userInfo = res.data.result
        } else {
          Toast('获取不到经营户信息')
        }
      })
    },

    // 金额格式校验
    rechargeChange () {
      // eslint-disable-next-line no-useless-escape
      this.totalPrice = this.totalPrice.replace(/[^\d\.]|^\./g, '').replace(/\.{2}/g, '.').replace(/^([1-9]\d*|0)(\.\d{1,2})(\.|\d{1})?$/, '$1$2').replace(/^0\d{1}/g, '0')
      Number(this.totalPrice) > 0
        ? this.disabled = false
        : this.disabled = true
    },

    // 跳转农行支付
    submit () {
      if (this.totalPrice > 0) {
        this.$api.payments.pay({
          merch_id: this.userInfo.merch_id,
          // merch_id: '709080000000653',
          orderType: 2,
          amount: this.moneyFormat(this.totalPrice),
          orderId: this.$route.query.orderId
        }).then(res => {
          if (res.status === 200 && res.data.scanCode) {
            window.location.href = res.data.scanCode
          } else {
            Toast('订单提交失败，请重新支付')
          }
        })
      } else {
        Toast('请输入支付金额')
      }
    }
  }
}
</script>

<style lang="less" scoped>
  @import "./index";
</style>
