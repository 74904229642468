<template>
  <div class="footer-wrapper st-body">
    <p class="total-info">
      总金额(元)：
      <s>{{ total }}</s>
    </p>
    <b
      :class="disabled ? 'button--disabled' : ''"
      @click="submit">
      提交订单
    </b>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    total: [String, Number],
    disabled: {
      type: Boolean,
      default: () => true
    }
  },
  methods: {
    submit () {
      if (!this.disabled) {
        this.$emit('submit')
      }
    }
  }
}
</script>

<style lang="less" scoped>
  @import "./index";
</style>
