import Vue from 'vue'
import VueRouter from 'vue-router'

import Index from '../views/index/Index'
import WaitPaid from '../views/orders/wait-paid/Index'
import OrderDetails from '../views/orders/details/Index'
import TracingRegister from '../views/tracings/register/Index'
import User from '../views/user/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/index',
    name: 'Index',
    component: Index,
    meta: {
      title: '请输入支付金额'
    }
  },
  {
    path: '/wait-paid',
    name: 'WaitPaid',
    component: WaitPaid,
    meta: {
      title: '确定订单'
    }
  },
  {
    path: '/order/details',
    name: 'OrderDetails',
    component: OrderDetails,
    meta: {
      title: '订单详情'
    }
  },
  {
    path: '/order/tracking',
    name: 'Tracking',
    component: () => import('../views/orders/details/Tracking'),
    meta: {
      title: '溯源详情'
    }
  },
  {
    path: '/order/tracking/product',
    name: 'Product',
    component: () => import('../views/orders/details/Product'),
    meta: {
      title: '商品详情'
    }
  },
  {
    path: '/ad',
    name: 'Ad',
    component: () => import('../views/orders/ad/Ad'),
    meta: {
      title: ''
    }
  },
  {
    path: '/tracing/register',
    name: 'TracingRegister',
    component: TracingRegister,
    meta: {
      title: '进场信息'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/Index'),
    meta: {
      title: '用户登录'
    }
  },
  {
    path: '/success',
    name: 'Success',
    component: () => import('../views/login/success/Success'),
    meta: {
      title: '用户登录成功'
    }
  },
  {
    path: '/sale',
    name: 'Sale',
    component: () => import('../views/sale/Index'),
    meta: {
      title: '售卖'
    }
  },
  {
    path: '/user',
    name: 'User',
    component: User,
    meta: {
      title:'用户中心'
    }
  },
  {
    path: '/user/orders/:id',
    name: 'UserOrders',
    component: () => import('../views/user/userOrders/Index'),
    meta: {
      title: '订单详情'
    }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

export default router
