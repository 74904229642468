<template>
  <div class="user-content">
    <div class="user">
      <div class="user-info">
        <div class="user-avatar"><img src="../../assets/images/user-shop.png"></div>
        <div class="user-name" v-if="userInfo.type === 0">用户：{{ userInfo.mobile }}</div>
        <div class="user-name" v-if="userInfo.type !== 0">{{ userInfo.name }}</div>
        <div class="user-detail" v-if="userInfo.type !== 0">
          <div class="address">{{ userInfo.market_name }}档口{{ userInfo.customCode }}</div>
          <div class="main-sell">主营：{{ userInfo.desc }}</div>
        </div>
      </div>
      <div class="search-bar">
        <input type="text" name="" id="" placeholder="输入商品名称搜索订单" v-model="keyword">
        <span class="icon" @click="doSearch"><img src="../../assets/images/search-active.png" alt=""></span>
      </div>
    </div>
    <list class="order-list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad"
          v-if="orderList.length > 0">
      <div class="order-list-item" v-for="(order,index) in orderList" :key="index">
        <div class="time-btn">
          <div class="time">{{ order.createTime }}</div>
          <div class="tap" @click="toDetail(index)">详情&溯源<span class="icon"><img
              src="../../assets/images/user-arrow.png" alt=""></span></div>
        </div>
        <div class="hline"></div>
        <div class="order-detail">
          <div class="good" v-for="(good, index2) in order.goodsList" :key="index2">
            {{ good.goodsName }} {{ good.goodsPrice }}元/{{ good.unit }}x{{ good.buyNumber }}
          </div>
        </div>
      </div>
    </list>
    <empty
        text="无订单记录"
        icon="empty-order"
        h="calc(83vh - 4rem)"
        v-else
    />
    <div class="logout" @click="logout">
      退出登录
    </div>
    <footer-btn
        text='返回'
        @confirm='back'
    />
  </div>
</template>

<script>
import {Dialog, List} from 'vant'
import FooterBtn from '../../components/footer/button/Index'
import Empty from '../../components/empty/Index'
import {deleteOneData, openDB} from '../../utils/index-db'

export default {
  name: '',
  components: {
    List,
    FooterBtn,
    Empty
  },
  data() {
    return {
      userInfo: {},
      orderList: [],
      loading: false,
      finished: false,
      page: 1,
      keyword: ''
    }
  },
  mounted() {
    openDB()
    this.userInfo = JSON.parse(localStorage.getItem('wh_user_info'))
    if (this.userInfo && this.userInfo.id) {
      this.getOrderItems()
    } else {
      this.$router.push({
        name: 'Login'
      })
    }
  },
  methods: {
    getOrderItems() {
      this.$api.userOrder.list(
          {
            userId: this.userInfo.id,
            orderState: 1,
            keyword: this.keyword,
            toGoPage: this.page
          }
      ).then(res => {
        this.orderList = res.data.orderList
      })
    },
    toDetail(index) {
      const id = this.orderList[index].orderId
      this.$router.push({
        name: 'UserOrders',
        params: {
          id: id
        }
      })
    },
    onLoad() {
      this.page += 1
      const page = this.page
      this.$api.userOrder.list(
          {
            userId: this.userInfo.id,
            orderState: 1,
            keyword: this.keyword,
            toGoPage: page
          }
      ).then(res => {
        if (page <= res.data.totalPage) {
          this.orderList = this.orderList.concat(res.data.orderList)
          this.loading = false
        } else {
          this.finished = true
          this.loading = false
        }
      })
    },
    logout() {
      Dialog.confirm({
        title: '温馨提示',
        message: '您确定要退出吗？'
      }).then(() => {
        deleteOneData(this.userInfo.id)
        this.$store.dispatch('deleteUserInfo')
      }).catch(() => {
        // todo
      })
    },
    doSearch() {
      const keyword = this.keyword
      this.page = 1
      const page = this.page
      this.orderList = []
      this.$api.userOrder.list(
          {
            userId: this.userInfo.id,
            orderState: 1,
            keyword: keyword,
            toGoPage: page
          }
      ).then(res => {
        this.orderList = res.data.orderList
        this.finished = false
      })
    },
    back() {
      this.$router.go(-1)
    }
  }
}
</script>


<style lang="less" scoped>
@import url('../user/Index');
</style>
