import vueStore from '../store'

let dbName = 'usersDB'    // 数据库名
let daVer = 1             // 数据库版本号
let db = ''               // 用于数据库对象
let dbData = []           // 用于临时存放数据的数组

// 连接数据库
export function openDB () {
  let request = indexedDB.open(dbName, daVer)
  request.onsuccess = function(e){
    db = e.target.result
    console.log('连接数据库成功')
    searchData()
  }
  request.onerror = function(){
    console.log('连接数据库失败')
  }
  request.onupgradeneeded = function(e){
    db = e.target.result
    // 如果不存在Users对象仓库则创建
    if(!db.objectStoreNames.contains('Users')){
      let store = db.createObjectStore('Users',{
        keyPath: 'id',
        autoIncrement: true
      })
      let idx = store.createIndex('index', 'username', { unique: false })
      console.log(idx)
    }
  }
}

/**
 * 保存数据
 * @param {Object} data 要保存到数据库的数据对象
 */
export function saveData(data){
  let tx = db.transaction('Users', 'readwrite')
  let store = tx.objectStore('Users')
  let req = store.put(data)
  req.onsuccess = function () {
    searchData()
  }
}

/**
 * 删除单条数据
 * @param {int} id 要删除的数据主键
 */
export function deleteOneData(id){
  var tx = db.transaction('Users', 'readwrite')
  var store = tx.objectStore('Users')
  var req = store.delete(id)
  req.onsuccess = function () {
    searchData()
  }
}

/**
 * 检索全部数据
 * @param {function} callback 回调函数
 */
export function searchData(callback){
  let tx = db.transaction('Users', 'readonly')
  let store = tx.objectStore('Users')
  let range = IDBKeyRange.lowerBound(1)
  let req = store.openCursor(range,'next')
  // 每次检索重新清空存放数据数组
  dbData = []
  req.onsuccess = function() {
    let cursor = this.result
    if (cursor) {
      // 把检索到的值push到数组中
      dbData.push(cursor.value)
      vueStore.dispatch('setUserInfo', dbData[0])
      cursor.continue()
    } else {
      // 数据检索完成后执行回调函数
      callback && callback()
    }
  }
}
