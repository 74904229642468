import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 样式表
import './assets/less/reset.less'
import './assets/less/base.less'
import 'vant/lib/index.css'
import './utils/wxFont.js'

// 相关配置
// api 接口
import { services } from './api/index'
Vue.prototype.$api = services
// 公共方法
import Format from './utils/format'
Vue.use(Format)
// 全局过滤器
import * as Filters from './utils/filters'
Object.keys(Filters).forEach(item =>
  Vue.filter(item, Filters[item])
)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
