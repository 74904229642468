export const moneyFormat = (value) => {
  if (value) {
    return (Math.round(value * 100) / 100).toFixed(2)
  }
  return '0.00'
}

export default {
  install (Vue) {
    Vue.prototype.moneyFormat = moneyFormat
  }
}
